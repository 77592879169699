/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBookedItems = /* GraphQL */ `
  mutation CreateBookedItems(
    $input: CreateBookedItemsInput!
    $condition: ModelBookedItemsConditionInput
  ) {
    createBookedItems(input: $input, condition: $condition) {
      id
      Amount
      LastBookedOut
      LastGoodsIn
      LastBookedOut_Amount
      LastGoodsIn_Amount
      GoodsIN
      EmailList
      ToolNumber
      CustomerName
      StorageLocation
      Rep
      Notes
      ToolNumberID
      CustomerNameID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBookedItems = /* GraphQL */ `
  mutation UpdateBookedItems(
    $input: UpdateBookedItemsInput!
    $condition: ModelBookedItemsConditionInput
  ) {
    updateBookedItems(input: $input, condition: $condition) {
      id
      Amount
      LastBookedOut
      LastGoodsIn
      LastBookedOut_Amount
      LastGoodsIn_Amount
      GoodsIN
      EmailList
      ToolNumber
      CustomerName
      StorageLocation
      Rep
      Notes
      ToolNumberID
      CustomerNameID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBookedItems = /* GraphQL */ `
  mutation DeleteBookedItems(
    $input: DeleteBookedItemsInput!
    $condition: ModelBookedItemsConditionInput
  ) {
    deleteBookedItems(input: $input, condition: $condition) {
      id
      Amount
      LastBookedOut
      LastGoodsIn
      LastBookedOut_Amount
      LastGoodsIn_Amount
      GoodsIN
      EmailList
      ToolNumber
      CustomerName
      StorageLocation
      Rep
      Notes
      ToolNumberID
      CustomerNameID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      eMail
      KeyUser
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      usersCustomerId
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      eMail
      KeyUser
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      usersCustomerId
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      eMail
      KeyUser
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      usersCustomerId
      __typename
    }
  }
`;
export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
      ToolNumber
      Specification
      Shape
      Dim_Nomclature
      Dim_Value
      Shelf_Level_Target
      Restock_Level
      Shelf_Level_Actual
      StorageLocation
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      CustomerPartNumber
      createdAt
      updatedAt
      materialCustomerId
      __typename
    }
  }
`;
export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      ToolNumber
      Specification
      Shape
      Dim_Nomclature
      Dim_Value
      Shelf_Level_Target
      Restock_Level
      Shelf_Level_Actual
      StorageLocation
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      CustomerPartNumber
      createdAt
      updatedAt
      materialCustomerId
      __typename
    }
  }
`;
export const deleteMaterial = /* GraphQL */ `
  mutation DeleteMaterial(
    $input: DeleteMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    deleteMaterial(input: $input, condition: $condition) {
      id
      ToolNumber
      Specification
      Shape
      Dim_Nomclature
      Dim_Value
      Shelf_Level_Target
      Restock_Level
      Shelf_Level_Actual
      StorageLocation
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      CustomerPartNumber
      createdAt
      updatedAt
      materialCustomerId
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      Company
      Rep
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      Company
      Rep
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      Company
      Rep
      createdAt
      updatedAt
      __typename
    }
  }
`;
