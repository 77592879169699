// ViewAllStockPage.js
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { listMaterials } from "./graphql/queries";
import { withAuthenticator, Button, Heading } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import "./ViewAllStockPage.css";

const ViewAllStockPage = ({ signOut }) => {
  const [materials, setMaterials] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    fetchMaterials();
  }, []);

  async function fetchMaterials() {
    try {
      let nextToken = null;
      let allMaterials = [];

      do {
        const result = await API.graphql({
          query: listMaterials,
          variables: { nextToken },
        });

        allMaterials = allMaterials.concat(result.data.listMaterials.items);
        nextToken = result.data.listMaterials.nextToken;
      } while (nextToken);

      setMaterials(allMaterials);
    } catch (error) {
      console.error("Error fetching materials:", error);
    }
  }

  return (
    <div className="ViewAllStockPage">
      <Heading level={1} className="page-title">
        All Stock Items
      </Heading>
      <div className="table-container">
        <table className="stock-table">
          <thead>
            <tr>
              <th>Tool Number</th>
              <th>Customer Number</th>
              <th>Location</th>
              <th>Specification</th>
              <th>Shape</th>
              <th>Dimensions</th>
              <th>Shelf Level</th>
              <th>Stock Target</th>
            </tr>
          </thead>
          <tbody>
            {materials.length > 0 ? (
              materials.map((material) => (
                <tr key={material.id}>
                  <td>
                    <a
                      href={`http://abrasiveconstock.com/products/Rack_1/AcmeLtd/${material.ToolNumber}`}
                      className="toolnumber-link"
                    >
                      {material.ToolNumber}
                    </a>
                  </td>
                  <td>{material.CustomerPartNumber}</td>
                  <td>{material.StorageLocation}</td>
                  <td>{material.Specification}</td>
                  <td>{material.Shape}</td>
                  <td>{material.Dim_Value}</td>
                  <td>{material.Shelf_Level_Actual}</td>
                  <td>{material.Shelf_Level_Target}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-data">
                  No materials found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Button className="go-back-button" onClick={() => navigate(-1)}>
        {" "}
        {/* Go Back Button */}
        Back
      </Button>
    </div>
  );
};

export default withAuthenticator(ViewAllStockPage);
