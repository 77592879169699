import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MaterialPage from "./MaterialPage"; // Import your ProductDetails component here
// Configure Amplify in index file or root file
import ViewAllStockPage from "./ViewAllStockPage"; // Import the new component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/products/:location/:company/:searchTerm?"
          element={<MaterialPage />}
        />
        <Route path="/view-all-stock" element={<ViewAllStockPage />} />

        {/* New Route */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
